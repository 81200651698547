import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SubSink } from 'subsink';
import { IOrganizationForm } from '@desquare/interfaces';
import { Maybe, Organization } from '@designage/gql';
import { TranslatePipe } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, TranslatePipe],
  selector: 'app-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss'],
})
export class OrganizationFormComponent
  implements OnInit, OnInit, OnChanges, OnDestroy
{
  private subs = new SubSink();

  @Output() submitted = new EventEmitter<IOrganizationForm>();
  @Output() values = new EventEmitter<IOrganizationForm>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() pristine = new EventEmitter<boolean>();

  @Input() formId = 'organizationForm';
  @Input() formHeader!: Maybe<string>;
  @Input() organization!: Maybe<Organization>;
  @Input() enableInput!: Maybe<boolean>;

  organizationForm!: FormGroup;
  maxLength!: number;
  minLength!: number;

  constructor(private formBuilder: FormBuilder) {}

  async ngOnInit() {
    this.initVariables();
    this.initForm();
    this.setFormState();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.organizationForm) {
      this.initForm();
    }
    if (
      changes.organization &&
      changes.organization.currentValue &&
      this.organization
    ) {
      this.setControlValues();
      this.pristine.emit(true);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.maxLength = 50;
    this.minLength = 2;

    this.valid.emit(false);
    this.pristine.emit(true);
  }

  initForm() {
    this.organizationForm = this.formBuilder.group({
      title: [null, Validators.required],
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(/^[A-Za-z0-9-]+$/),
        ],
      ],
    });

    if (this.organization) {
      this.setControlValues();
    }
  }

  setFormState() {
    this.subs.sink = this.organizationForm.valueChanges.subscribe(() => {
      this.pristine.emit(this.organizationForm.pristine);
      this.valid.emit(this.organizationForm.valid);
      this.values.emit(this.organizationForm.value);
    });

    this.subs.sink =
      this.organizationForm.controls.title.valueChanges.subscribe(
        (value: string) => {
          this.organizationForm.patchValue({
            name: value.replace(/\s+/g, '-').toLowerCase(),
          });
        },
      );
  }

  setControlValues() {
    if (this.organization) {
      this.organizationForm.patchValue({
        name: this.organization.name,
        title: this.organization.title,
      });
    }
  }

  // submit event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitForm(event: any, values: IOrganizationForm) {
    if (this.organization && this.organization.id) {
      values.id = this.organization.id;
      this.submitted.emit(values);
    }
  }
}
