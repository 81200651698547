import { Component } from '@angular/core';
import { IOrganizationForm } from '@desquare/interfaces';
import { TranslatePipe } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslatePipe],
  selector: 'app-organization-saving-dialog',
  templateUrl: './organization-saving-dialog.component.html',
  styleUrls: ['./organization-saving-dialog.component.scss'],
})
export class OrganizationSavingDialogComponent {
  values?: IOrganizationForm;
}
