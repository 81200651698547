import { SubSink } from 'subsink';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  CreateOrganizationGQL,
  GetAllOrganizationsForOrganizationSelectionQuery,
  GetAllOrganizationsForOrganizationSelectionGQL,
  GetAllOrganizationsForOrganizationSelectionDocument,
} from '@designage/gql';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@desquare/services';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CreateOrganizationDialogComponent } from '../create-organization-dialog/create-organization-dialog.component';
import { IOrganizationForm } from '@desquare/interfaces';
import { ApolloError } from '@apollo/client/errors';
import { OrganizationSavingDialogComponent } from '../organization-saving-dialog/organization-saving-dialog.component';
import { ExcludeMaybe } from '@desquare/types';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [ReactiveFormsModule, TranslatePipe],
  selector: 'app-organization-selection',
  template: `
    <div class="input-group" [formGroup]="organizationForm">
      <select
        name="organizationId"
        class="form-select form-select-dark"
        formControlName="organizationId"
      >
        @if (loading) {
          <option selected>
            <div
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            >
              <span class="text-secondary visually-hidden">
                {{ 'LOADING' | translate }}...
              </span>
            </div>
          </option>
        } @else {
          @if (organizations && organizations.length > 0) {
            <option disabled [ngValue]="null" selected>
              {{ 'SELECT_AN_ORGANIZATION' | translate }}
            </option>
          } @else {
            <option [ngValue]="null" selected>
              {{ 'NO_ORGANIZATIONS_FOUND' | translate }}
            </option>
          }
          @for (organization of organizations; track organization.id) {
            <option [ngValue]="organization.id">
              {{ organization.title }}
            </option>
          }
        }
      </select>
    </div>
  `,
})
export class OrganizationSelectionComponent implements OnInit, OnChanges {
  private subs = new SubSink();

  @Input() profileId?: string;
  @Input() selectedOrganizationId?: string;
  @Output() createdOrganization = new EventEmitter<
    ExcludeMaybe<
      GetAllOrganizationsForOrganizationSelectionQuery['organizations']
    >['results'][number]
  >();
  @Output() selectionChange = new EventEmitter<
    ExcludeMaybe<
      GetAllOrganizationsForOrganizationSelectionQuery['organizations']
    >['results'][number]
  >();
  organizations!: ExcludeMaybe<
    GetAllOrganizationsForOrganizationSelectionQuery['organizations']
  >['results'];
  isConfirmationOpen = false;
  loading = false;
  loaderMessage!: string;
  organizationForm!: FormGroup;
  duplicateOrganizations?: number;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private toasterService: ToasterService,
    private createOrganizationGQL: CreateOrganizationGQL,
    private getOrganizationsGQL: GetAllOrganizationsForOrganizationSelectionGQL,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profileId) {
      this.initForm();
      this.getOrganizations();
    }
  }

  initForm() {
    this.organizationForm = this.formBuilder.group({
      organizationId: [this.selectedOrganizationId],
    });
    this.organizationForm.controls.organizationId.valueChanges.subscribe(
      (value) => {
        const organization = this.organizations.find((x) => x.id === value);

        if (organization) {
          this.selectionChange.emit(organization);
        }
      },
    );
  }

  getOrganizations() {
    this.loading = true;
    this.loaderMessage = 'FETCHING_ORGANIZATIONS';
    this.subs.sink = this.getOrganizationsGQL
      .watch(
        {
          active: true,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .valueChanges.subscribe({
        next: ({ data, loading }) => {
          this.loading = loading;
          const { organizations } = data;

          if (organizations) {
            this.organizations = organizations.results;
          } else {
            this.organizations = [];
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('getOrganizations', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
          this.loading = false;
        },
      });
  }

  openCreateOrganizationDialog() {
    const modalRef = this.modalService.open(CreateOrganizationDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.result
      .then((values: IOrganizationForm) => {
        if (this.profileId) {
          this.checkDuplicateOrganization(values);
        }
      })
      .catch(() => {});
  }

  checkDuplicateOrganization(form: IOrganizationForm) {
    const { title } = form;
    this.loaderMessage = 'CHECKING_FOR_DUPLICATE_ORGANIZATION';

    this.subs.sink = this.getOrganizationsGQL
      .fetch({ title, active: true }, { fetchPolicy: 'network-only' })
      .subscribe({
        next: ({ data, loading }) => {
          this.loading = loading;

          if (data?.organizations) {
            this.duplicateOrganizations = data.organizations.total;

            if (this.duplicateOrganizations > 0) {
              this.toasterService.error('ORGANIZATION_ALREADY_EXISTS');
            } else {
              this.openOrganizationPromptDialog(form);
            }
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('checkDuplicateOrganization', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
          this.loading = false;
        },
      });
  }
  openOrganizationPromptDialog(form: IOrganizationForm) {
    const modalRef = this.modalService.open(OrganizationSavingDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.values = form;
    modalRef.result
      .then(() => {
        this.createOrganization(form);
      })
      .catch(() => {});
  }

  createOrganization(input: IOrganizationForm) {
    this.loading = true;
    this.loaderMessage = 'CREATING_ORGANIZATION';

    this.subs.sink = this.createOrganizationGQL
      .mutate(
        { input },
        {
          update: (proxy, results) => {
            // try catch to prevent error thrown when cache has no organization.results
            try {
              const data =
                proxy.readQuery<GetAllOrganizationsForOrganizationSelectionQuery>(
                  {
                    query: GetAllOrganizationsForOrganizationSelectionDocument,
                    variables: { active: true },
                  },
                );
              if (
                data?.organizations &&
                results.data?.createOrganization?.organization
              ) {
                data.organizations.results.push(
                  results.data.createOrganization.organization,
                );
                data.organizations.results.sort((a, b) =>
                  a.name && b.name && a.name > b.name ? 1 : -1,
                );
                proxy.writeQuery({
                  query: GetAllOrganizationsForOrganizationSelectionDocument,
                  variables: { active: true },
                  data,
                });
              }
            } catch (error) {
              // do nothing
            }
          },
        },
      )
      .subscribe({
        next: ({ data }) => {
          if (data && data.createOrganization.isSuccessful) {
            this.toasterService.success('CREATE_ORGANIZATION_SUCCESS');
            if (data.createOrganization.organization) {
              this.organizationForm.patchValue({
                organizationId: data.createOrganization.organization?.id,
              });
            }
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }
          this.loading = false;
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('createOrganization', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
          this.loading = false;
        },
      });
  }
}
