import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { StorageSummaryType, FileSummary } from '@designage/gql';
import { TranslatePipe } from '@ngx-translate/core';
import {
  CurrentUserService,
  MediaService,
  ProfileService,
} from '@desquare/services';
import { IDesignageDataTableColumns } from '@desquare/interfaces';
import { DesignageDataTableComponent } from '../designage-data-table/designage-data-table.component';
import { lastValueFrom } from 'rxjs';

@Component({
  standalone: true,
  imports: [TranslatePipe, DesignageDataTableComponent],
  selector: 'app-media-storage-summary',
  template: `<div class="">
    <h5>{{ 'STORAGE_PROFILE_SUMMARY' | translate }}</h5>
    <designage-data-table
      configId="storage-summary"
      [data]="storageData()"
      [columns]="columns"
      [loading]="loading()"
      [showFilter]="false"
      [columnSelector]="false"
      [showFooter]="false"
    ></designage-data-table>

    @if (isSuperAdmin) {
      <div class="d-flex justify-content-start gap-2 mb-3">
        <button class="btn btn-outline-warning" (click)="purge()">
          Purge transformations
        </button>
        <button class="btn btn-outline-danger" (click)="destroy()">
          Destroy
        </button>
      </div>
    }
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageSummaryComponent implements OnInit {
  currentUserService = inject(CurrentUserService);
  mediaService = inject(MediaService);
  profileService = inject(ProfileService);
  @Input() showButtons = false;
  @Input() showNonActive = false;

  columns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'label',
      name: '',
      type: 'string',
      visible: 'mandatory',
      disableSorting: true,
    },
    {
      fieldName: 'fileCount',
      name: 'Files',
      type: 'string',
      visible: 'mandatory',
      disableSorting: true,
    },
    {
      fieldName: 'totalBytes',
      name: 'Data size',
      type: 'string',
      decorator: 'fileSize',
      visible: 'mandatory',
      disableSorting: true,
    },
  ];
  loading = signal<boolean>(false);
  storageData = signal<FileSummary[]>([]);
  isSuperAdmin = false;

  ngOnInit() {
    this.initVariables();
  }

  async initVariables() {
    this.isSuperAdmin = this.currentUserService.isSuperAdmin;
    await this.getStorageData();
  }
  async getStorageData() {
    const types: StorageSummaryType[] = [
      StorageSummaryType.Total,
      StorageSummaryType.Parent,
      StorageSummaryType.Derived,
      StorageSummaryType.NonUsedParent,
      StorageSummaryType.NonUsedDerived,
      StorageSummaryType.NonActiveParent,
      StorageSummaryType.NonActiveDerived,
    ];
    this.loading.set(true);
    const newData = await this.mediaService.getStorageSummary(types);
    console.log('storage summary data', newData);
    this.storageData.set(newData);
    this.loading.set(false);
  }

  async purge() {
    const profileId = this.currentUserService.getCurrentProfileId();
    if (profileId && this.isSuperAdmin) {
      const ret = await lastValueFrom(
        this.profileService.purge.mutate({ id: profileId }),
      );
      console.log('purge', ret);
    }
  }
  async destroy() {
    const profileId = this.currentUserService.getCurrentProfileId();
    if (profileId && this.isSuperAdmin) {
      const ret = await lastValueFrom(
        this.profileService.destroy.mutate({ id: profileId }),
      );
      console.log('destroy', ret);
    }
  }
}
